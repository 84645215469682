<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-16 09:23:59
 * @LastEditTime: 2020-12-25 15:55:39
-->
<template>
  <div>
    <!-- 实名信息 -->
    <van-form ref="formData"
              class="u-form">
      <van-field required
                 clearable
                 v-model="formData.username"
                 label="真实姓名"
                 :rules="[{ required: true, message: '请输入真实姓名' }]" />
      <van-field required
                 readonly
                 label="证件类型"
                 value="身份证" />
      <van-field v-model="formData.certificateContent"
                 required
                 readonly
                 clickable
                 maxlength="18"
                 clearable
                 label="身份证号"
                 @click="show=true"
                 placeholder="请输入身份证号"
                 :rules="[
          {
            required: true,
            validator: certificateContentValidator,
            message: '请输入正确身份证号',
            trigger: 'onChange',
          },
        ]" />
      <van-field required
                 readonly
                 :value="formData.image ? '已提交' : '未提交'"
                 label="人脸验证">
        <template #button>
          <span class="faceVerification"
                @click="faceVerification">{{formData.image ? '修改' : '开始认证'}}</span>
        </template>
      </van-field>
    </van-form>
    <div class="bottom-btn">
      <u-button type="primary"
                size="large"
                :disabled="nextDisabled||sending"
                @click="next">下一步</u-button>
    </div>
    <van-number-keyboard extra-key="X"
                         close-button-text="完成"
                         v-model="formData.certificateContent"
                         :show="show"
                         :maxlength="18"
                         @blur="show = false" />
  </div>
</template>

<script>
import UButton from "@/components/u-button";
import api from "@api";
export default {
  name: "",
  components: { UButton },
  props: {
    data: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      roleData: JSON.parse(localStorage.getItem("roleData")),
      formData: {
        username: "",
        certificateContent: "",
        image: "",
      },
      sending: false,
      show: false,
      msgs: {
        '2001': '人脸照片大小超过限制，最大为100k',
        '2002': '人脸照片质量不合格，请重新拍摄',
        '2003': '无效身份证号，请核实重新录入',
        '2004': '姓名与身份证号码不一致',
        '2005': '人像照片校验非活体，请重新拍摄'
      }
    };
  },
  computed: {
    nextDisabled () {
      return !(
        this.formData.image &&
        this.certificateContentValidator(this.formData.certificateContent) &&
        this.formData.username
      );
    },
  },
  mounted () {
    this.formData.username = this.roleData.userPO.userName || "";
    this.formData.certificateContent = this.roleData.userPO.certificateNum;
    this.formData.image = this.roleData.userPO.rlsbImage;
    this.formData.userId = this.roleData.userPO.id;
  },
  methods: {
    certificateContentValidator (val) {
      return this.$tools.isIdCard(val);
    },
    // 人脸验证 修改
    faceVerification () {
      this.$wx.chooseImage({
        count: 1, //可选照片数量
        sizeType: ["compressed"], //可以指定是原图还是压缩图，默认二者都有
        sourceType: ["camera"], //从相册选择
        success: (res) => {
          this.syncImgData(res.localIds);
        },
        fail: (err) => {
          console.log("failChoose", err);
        },
      });
    },
    // 本地图片显示转换base64
    syncImgData (localIds) {
      var localId = localIds[0];
      var that = this;
      this.$wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (resBackData) {
          var localData = resBackData.localData;
          let imageBase64 = "";
          // 这里处理的是ios和安卓的区别
          if (localData.indexOf("data:image") == 0) {
            imageBase64 = localData;
          } else {
            imageBase64 =
              "data:image/jpeg;base64," + localData.replace(/\n/g, "");
          }
          that.$tools.compressImage(imageBase64, {}, (data) => {
            // 图片压缩
            that.formData.image = data;
          });
          // if (localIds.length > 0) {
          //   that.syncImgData(localIds);
          // }
        },
      });
    },
    next () {
      this.$refs.formData.validate().then((res) => {
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '数据提交中',
        });
        this.sending = true
        api.saveRealName(this.formData).then((res) => {
          toast.close()
          this.sending = false
          if (res.code == "SUCCESS") {
            if (res.data.header.code === '00') {
              if (res.data.body.compStatus == '0') { //认证失败
                this.$toast.fail("人证不一致，请重新上传")
              } else if (res.data.body.compStatus == '1'){ // 认证成功 compStatus == '1'
                this.roleData.userPO.userName = this.formData.username
                this.roleData.userPO.certificateNum = this.formData.certificateContent
                this.roleData.userPO.rlsbImage = this.formData.image
                this.roleData.userPO.id = this.formData.userId
                this.$store.dispatch("setRoleData", this.roleData); //vuex 存roleData 信息
                localStorage.setItem("roleData", JSON.stringify(this.roleData)); //本地缓存roleData 信息
                this.$emit("next");
              }
            } else {
              if (Object.keys(this.msgs).indexOf(res.data.header.code) > -1) {
                this.$toast.fail(this.msgs[res.data.header.code])
              } else {
                this.$toast.fail("认证失败")
              }
            }
          } else {
            this.$toast.fail("认证失败")
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.faceVerification {
  font-size: 0.8rem /* 15/18.75 */;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2b71fd;
}
</style>