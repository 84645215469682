<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-15 14:40:48
 * @LastEditTime: 2020-12-24 09:58:54
-->
<template>
  <div class="realNameAuthentication h-100 bg-gray">
    <div class="box step">
      <step :steps="steps"
            :active="active"></step>
    </div>
    <personal-form @next="next"
                   v-if="active===0"></personal-form>
    <realName-form @next="next"
                   v-else-if="active===1"></realName-form>
    <live-form @next="next"
               v-else-if="active===2"></live-form>
  </div>
</template>

<script>
import step from '@/components/step';
import PersonalForm from './com/personalForm'
import RealNameForm from './com/realNameForm'
import LiveForm from './com/liveForm'
export default {
  name: 'realNameAuthentication',
  components: { step, PersonalForm, RealNameForm, LiveForm },
  data () {
    return {
      roleData: JSON.parse(localStorage.getItem("roleData")),
      active: 0,
      steps: [
        '个人信息',
        '实名信息',
        '居住信息'
      ],
    }
  },
  mounted () {
    // 实名验证状态判断
    this.check()
  },
  watch: {
  },
  methods: {
    check () {
      var zones = this.roleData.xiaoqu.filter(
		    (item) =>
        (item.userZoneRelationPO.status === 2 ||
        item.userZoneRelationPO.status === 1) &&
		    item.userZoneRelationPO.cancel === 0
      ); // （审核通过或待审核）并未注销的房屋
      if (this.roleData.userPO.phone) {
        console.log(2, this.roleData)
        if (this.roleData.userPO.rlsbStatus == 1) {
          if (zones.length > 0) {
            this.$dialog.alert({
              message: '你已经实名验证过',
            }).then(() => {
              this.$router.go(-1)
            });
          } else {
            this.active = 2
          }
        } else {
          this.active = 1
        }
      } else {
        this.active = 0
      }
      // if (zones.length > 0) {
      //   this.$dialog.alert({
      //     message: '你已经实名验证过',
      //   }).then(() => {
      //     this.$router.go(-1)
      //   });
      // } else if (this.roleData.userPO.rlsbStatus === 1) {
      //   this.active = 2
      // } else if (this.roleData.userPO.phone) {
      //   this.active = 1
      // }
    },
    // 下一步
    next () {
      if (this.active < this.steps.length - 1) {
        this.active++
      }
    }
  },
}
</script>

<style lang="less" scoped>
</style>