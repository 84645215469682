var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-form',{ref:"formData",staticClass:"u-form"},[_c('van-field',{attrs:{"required":"","clearable":"","label":"真实姓名","rules":[{ required: true, message: '请输入真实姓名' }]},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('van-field',{attrs:{"required":"","readonly":"","label":"证件类型","value":"身份证"}}),_c('van-field',{attrs:{"required":"","readonly":"","clickable":"","maxlength":"18","clearable":"","label":"身份证号","placeholder":"请输入身份证号","rules":[
        {
          required: true,
          validator: _vm.certificateContentValidator,
          message: '请输入正确身份证号',
          trigger: 'onChange',
        },
      ]},on:{"click":function($event){_vm.show=true}},model:{value:(_vm.formData.certificateContent),callback:function ($$v) {_vm.$set(_vm.formData, "certificateContent", $$v)},expression:"formData.certificateContent"}}),_c('van-field',{attrs:{"required":"","readonly":"","value":_vm.formData.image ? '已提交' : '未提交',"label":"人脸验证"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('span',{staticClass:"faceVerification",on:{"click":_vm.faceVerification}},[_vm._v(_vm._s(_vm.formData.image ? '修改' : '开始认证'))])]},proxy:true}])})],1),_c('div',{staticClass:"bottom-btn"},[_c('u-button',{attrs:{"type":"primary","size":"large","disabled":_vm.nextDisabled||_vm.sending},on:{"click":_vm.next}},[_vm._v("下一步")])],1),_c('van-number-keyboard',{attrs:{"extra-key":"X","close-button-text":"完成","show":_vm.show,"maxlength":18},on:{"blur":function($event){_vm.show = false}},model:{value:(_vm.formData.certificateContent),callback:function ($$v) {_vm.$set(_vm.formData, "certificateContent", $$v)},expression:"formData.certificateContent"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }