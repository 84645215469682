<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-16 09:23:59
 * @LastEditTime: 2021-01-28 14:59:16
-->
<template>
  <div>
    <!-- 居住信息 -->
    <van-form ref="formData" class="u-form">
      <van-field
        right-icon="arrow"
        readonly
        required
        clickable
        label="类型"
        v-model="formData.userType"
        :rules="[{ required: true, message: '请选择身份类型' }]"
        placeholder="点击选择身份类型"
        @click="
          showPicker = true;
          pickType = 'userType';
        "
      />
      <van-field
        right-icon="arrow"
        required
        readonly
        clickable
        label="省市区"
        v-model="formData.region"
        :rules="[{ required: true, message: '请选择省市区' }]"
        placeholder="点击选择省市区"
        @click="showAreaPicker = true"
      />
      <van-field
        right-icon="arrow"
        required
        readonly
        clickable
        label="乡镇街道"
        v-model="formData.street"
        :rules="[{ required: true, message: '请选择街道信息' }]"
        placeholder="点击选择街道信息"
        @click="
          showPicker = true;
          pickType = 'street';
        "
      />
      <van-field
        right-icon="arrow"
        required
        readonly
        clickable
        label="村社区"
        v-model="formData.community"
        :rules="[{ required: true, message: '请选择村社区信息' }]"
        placeholder="点击选择村社区信息"
        @click="
          showPicker = true;
          pickType = 'community';
        "
      />
      <!-- <van-field required
                 v-model="formData.xqName"
                 label="小区/自然村"
                 placeholder="请输入所屬小区/自然村地址"
                 :rules="[{ required: true, message: '请输入所屬小区/自然村地址' }]" /> -->
      <div class="van-cell--required van-cell van-field">
        <div class="van-cell__title van-field__label">
          <span>小区/自然村</span>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="my my-popover">
            <van-popover
              v-model="showPopover"
              trigger="click"
              @select="xqSelect"
              :actions="actions"
              get-container=".u-form"
            >
              <template #reference>
                <input
                  type="text"
                  @blur="showPopover = false"
                  @input="xqInput"
                  v-model="formData.xqName"
                  placeholder="请输入所屬小区/自然村地址"
                />
              </template>
            </van-popover>
          </div>
        </div>
      </div>
      <div class="van-cell--required van-cell van-field">
        <div class="van-cell__title van-field__label">
          <span>详细地址</span>
        </div>
        <div class="van-cell__value van-field__value">
          <div class="my">
            <input
              type="text"
              readonly
              @click="
                showPicker = true;
                pickType = 'ridgepole';
              "
              v-model="formData.ridgepole"
            />栋
            <input
              type="text"
              readonly
              @click="
                showPicker = true;
                pickType = 'unit';
              "
              v-model="formData.unit"
            />单元 <input type="text" v-model="formData.chamber" />室
          </div>
        </div>
      </div>
      <van-field
        right-icon="arrow"
        v-if="isLadderControl"
        readonly
        required
        clickable
        label="楼层"
        v-model="formData.floor"
        :rules="[{ required: true, message: '请选择楼层' }]"
        placeholder="请选择楼层"
        @click="
          showPicker = true;
          pickType = 'floor';
        "
      />
    </van-form>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :title="pickerTitle"
        :columns="columns[this.pickType]"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showAreaPicker" round position="bottom">
      <van-area
        title="省市区"
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="showAreaPicker = false"
      />
    </van-popup>
    <div class="bottom-btn">
      <u-button
        type="primary"
        size="large"
        :disabled="disabled || sending"
        @click="submit"
        >提交</u-button
      >
    </div>
  </div>
</template>

<script>
import UButton from "@/components/u-button";
import api from "@api";
import { falseTestGetUserInfo } from "@/utils/aRequest";
export default {
  name: "",
  components: { UButton },
  props: {
    data: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      roleData: JSON.parse(localStorage.getItem("roleData")),
      formData: {
        userType: "",
        region: "",
        street: "",
        community: "",
        xqName: "",
        ridgepole: "",
        unit: "",
        floor: ""
      },
      pickType: "",
      columns: {
        userType: [
          {
            value: 0,
            text: "业主"
          },
          {
            value: 4,
            text: "二房东"
          },
          {
            value: 5,
            text: "中介"
          },
          {
            value: 1,
            text: "租户"
          },
          {
            value: 2,
            text: "家属"
          },
          {
            value: 7,
            text: "商铺"
          },
          {
            value: 6,
            text: "物业"
          },
          {
            value: 99,
            text: "其它"
          }
        ],
        street: [],
        community: [],
        ridgepole: [],
        unit: [],
        floor: []
      },
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      },
      showPopover: false,
      isChamber: false, // 室必填
      isLadderControl: false, // 梯控楼层
      xqList: [],
      showPicker: false,
      showAreaPicker: false,
      sending: false
    };
  },
  mounted() {
    this.getAreaList();
    // this.roleData.xiaoqu[0].userZoneRelationPO.status = 0;
    var zones = this.roleData.xiaoqu.filter(
      item =>
        item.userZoneRelationPO.status === 0 &&
        item.userZoneRelationPO.cancel === 0
    ); // 有房屋但未完善
    if (zones.length) {
      let zone = zones[0].zonePO;
      // console.log("zone: ", zone);
      this.formData.region = zone.region;
      this.formData.regionCode = zone.regionCode;

      this.formData.street = zone.street;
      this.formData.streetCode = zone.streetCode;

      this.formData.community = zone.community;
      this.formData.communityCode = zone.communityCode;

      this.formData.xqName = zone.name;
      this.formData.zoneId = zone.id;

      this.getStreetList(zone.regionCode);
      this.getCommunityList(zone.streetCode);
      this.getXqList(zone.communityCode).then(() => {
        this.xqList.forEach(xq => {
          if (xq.id === zone.id) {
            this.columns.ridgepole = this.getArray(xq.ridgepole);
            this.columns.unit = this.getArray(xq.unit);
            this.isChamber = !(xq.xqType && xq.xqType === 1);
            this.isLadderControl =
              xq.ladderControlFlag && xq.ladderControlFlag === 1;
            if (this.isLadderControl) {
              this.columns.floor = this.getArray(xq.maxFloor);
            }
          }
        });
      });
    } else {
      this.columns.ridgepole = this.getArray(100);
      this.columns.unit = this.getArray(100);
      this.$wx.ready(() => {
        this.$wx.getLocation({
          type: "wgs84",
          success: res => {
            let data = {
              location: `${res.latitude},${res.longitude}`,
              key: "ILYBZ-7ZMK2-RPMU3-CSPUT-62L5T-LNFX5",
              output: "jsonp"
            };
            this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1`, data)
              .then(res => {
                if (!res.status) {
                  let info = res.result.ad_info;
                  this.formData.region =
                    info.province + "/" + info.city + "/" + info.district;
                  this.formData.regionCode = info.adcode;
                  this.getStreetList(info.adcode);
                }
              })
              .catch(err => {});
          }
        });
      });
    }
  },
  computed: {
    disabled() {
      let requireds = [
        "userType",
        "region",
        "street",
        "community",
        "xqName",
        "ridgepole",
        "unit"
      ];
      let disabled = false;
      requireds.forEach(required => {
        if (!this.formData[required]) {
          disabled = true;
        }
      });
      if (!this.formData.chamber && this.isChamber) {
        disabled = true;
      }
      return disabled;
    },
    pickerTitle() {
      let pickerTitle = "";
      switch (this.pickType) {
        case "userType":
          pickerTitle = "身份类型";
          break;
        case "street":
          pickerTitle = "街道信息";
          break;
        case "community":
          pickerTitle = "社区信息";
          break;
        case "ridgepole":
          pickerTitle = "栋";
          break;
        case "unit":
          pickerTitle = "单元";
          break;
      }
      return pickerTitle;
    },
    actions: {
      get() {
        let actions = [];
        if (this.xqList.length && this.formData.xqName) {
          actions = this.xqList.filter(xq => {
            return xq.text.indexOf(this.formData.xqName) !== -1;
          });
        } else {
          actions = this.xqList;
        }
        return actions;
      },
      set(v) {}
    }
  },
  methods: {
    // 获取picker数据(栋,单元)
    getArray(num) {
      let arr = [];
      for (let i = 1; i <= num; i++) {
        arr.push({ text: i });
      }
      return arr;
    },
    // 获取行政区划的列表
    getAreaList() {
      api.getAreaList().then(res => {
        res.data.forEach(province => {
          this.areaList.province_list[province.provinceCode] =
            province.provinceName;
          province.cityList.forEach(city => {
            this.areaList.city_list[city.cityCode] = city.cityName;
            city.areaList.forEach(area => {
              this.areaList.county_list[area.areaCode] = area.areaName;
            });
          });
        });
      });
    },
    // 省市县弹窗确定
    onAreaConfirm(areas) {
      if (areas) {
        let region = areas[0].name + "/" + areas[1].name + "/" + areas[2].name;
        this.formData.region = region;
        this.formData.regionCode = areas[2].code;
        // 重置街道以及社区信息以及小区信息
        this.columns.street = [];
        this.formData.street = "";
        this.formData.streetCode = "";
        this.columns.community = [];
        this.formData.community = "";
        this.formData.communityCode = "";
        this.actions = [];
        this.formData.xqName = "";
        this.formData.zoneId = null;
        // 获取街道信息
        this.getStreetList(areas[2].code);
      }
      this.showAreaPicker = false;
    },
    // 获取街道列表
    getStreetList(areaCode) {
      api.getStreetList(areaCode).then(res => {
        this.columns.street = res.data.map(item => {
          return {
            value: item.townCode,
            text: item.townName
          };
        });
      });
    },
    // 获取社区列表
    getCommunityList(townCode) {
      api.getCommunityList(townCode).then(res => {
        this.columns.community = res.data.map(item => {
          return {
            value: item.villageCode,
            text: item.villageName
          };
        });
      });
    },
    // 获取小区列表
    getXqList(communityCode) {
      return api.getXqList(communityCode).then(res => {
        this.xqList = res.data.map(item => {
          return {
            text: item.name,
            id: item.id,
            ridgepole: parseInt(item.building),
            unit: parseInt(item.unit),
            maxFloor: parseInt(item.maxFloor),
            ladderControlFlag: item.ladderControlFlag,
            xqType: item.xqType
          };
        });
        return Promise.resolve();
      });
    },
    // 小区选择
    xqSelect(data) {
      if (data) {
        this.formData.xqName = data.text;
        this.formData.zoneId = data.id;
        this.columns.ridgepole = this.getArray(data.ridgepole);
        this.columns.unit = this.getArray(data.unit);
        this.isChamber = !(data.xqType && data.xqType === 1);
        this.isLadderControl =
          data.ladderControlFlag && data.ladderControlFlag === 1;
        if (this.isLadderControl) {
          this.columns.floor = this.getArray(data.maxFloor);
        }
      }
      this.showPopover = false;
    },
    xqInput() {
      this.columns.ridgepole = this.getArray(100);
      this.columns.unit = this.getArray(100);
      this.formData.zoneId = null;
      this.showPopover = true;
    },
    // 弹窗选择
    onConfirm(value) {
      if (value) {
        this.formData[this.pickType] = value.text;
        value.value && (this.formData[this.pickType + "Code"] = value.value);
        if (this.pickType === "street") {
          // 重置社区信息
          this.columns.community = [];
          this.formData.community = "";
          this.formData.communityCode = "";
          // 获取社区信息
          this.getCommunityList(value.value);
        } else if (this.pickType === "community") {
          // 重置小区信息
          this.actions = [];
          this.formData.xqName = "";
          this.formData.zoneId = null;
          // 获取小区信息
          this.getXqList(value.value);
        }
      }
      this.showPicker = false;
    },
    submit() {
      this.$refs.formData.validate().then(res => {
        let formData = this.$tools.getJson(this.formData);
        formData.userType = formData.userTypeCode || 0;
        formData.zoneType = 0;
        delete formData.userTypeCode;
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "数据提交中"
        });
        this.sending = true;
        api.saveLive(formData).then(res => {
          toast.close();
          this.sending = false;
          if (res.code == "SUCCESS") {
            let openId = JSON.parse(localStorage.getItem("roleData")).userPO.gzhOpenId;
            falseTestGetUserInfo({ gzhOpenId: openId }).then(data => {
              if (data.code == "SUCCESS") {
                this.$cookie.set("sessionId", data.data.sessionId, 1); // 设置cookie
                localStorage.setItem("sessionId", data.data.sessionId);
                this.$store.dispatch("setRoleData", data.data); //vuex 存roleData 信息
                localStorage.setItem("roleData", JSON.stringify(data.data)); //本地缓存roleData 信息
                this.$router.replace({
                  path: "/realNameAuthenticationResult",
                  query: {
                    type: this.$route.query.type,
                    id: this.$route.query.id
                  }
                });
              }
            });
          }
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.u-form {
  /deep/.van-popup {
    transform: translate(-1.066667rem /* 20/18.75 */);
  }
  /deep/.van-popover__action {
    width: 10.666667rem /* 200/18.75 */;
  }
}
.my {
  display: flex;
  line-height: 1.28rem /* 24/18.75 */;
  &.my-popover {
    .van-popover__wrapper {
      width: 100%;
      input {
        width: 100% !important;
        text-align: left;
      }
    }
  }
  input {
    border: none;
    line-height: 1.28rem /* 24/18.75 */;
    margin-right: 0.266667rem /* 5/18.75 */;
    color: @fcb;
    text-align: right;
    &:nth-child(1) {
      width: 1.6rem /* 30/18.75 */;
    }
    &:nth-child(2) {
      width: 2.933333rem /* 55/18.75 */;
    }
    &:nth-child(3) {
      width: 2.933333rem /* 55/18.75 */;
    }
  }
}
</style>
