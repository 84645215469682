var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-form',{ref:"formData",staticClass:"u-form"},[_c('van-field',{attrs:{"right-icon":"arrow","readonly":"","required":"","clickable":"","label":"类型","rules":[{ required: true, message: '请选择身份类型' }],"placeholder":"点击选择身份类型"},on:{"click":function($event){_vm.showPicker = true;
        _vm.pickType = 'userType';}},model:{value:(_vm.formData.userType),callback:function ($$v) {_vm.$set(_vm.formData, "userType", $$v)},expression:"formData.userType"}}),_c('van-field',{attrs:{"right-icon":"arrow","required":"","readonly":"","clickable":"","label":"省市区","rules":[{ required: true, message: '请选择省市区' }],"placeholder":"点击选择省市区"},on:{"click":function($event){_vm.showAreaPicker = true}},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}}),_c('van-field',{attrs:{"right-icon":"arrow","required":"","readonly":"","clickable":"","label":"乡镇街道","rules":[{ required: true, message: '请选择街道信息' }],"placeholder":"点击选择街道信息"},on:{"click":function($event){_vm.showPicker = true;
        _vm.pickType = 'street';}},model:{value:(_vm.formData.street),callback:function ($$v) {_vm.$set(_vm.formData, "street", $$v)},expression:"formData.street"}}),_c('van-field',{attrs:{"right-icon":"arrow","required":"","readonly":"","clickable":"","label":"村社区","rules":[{ required: true, message: '请选择村社区信息' }],"placeholder":"点击选择村社区信息"},on:{"click":function($event){_vm.showPicker = true;
        _vm.pickType = 'community';}},model:{value:(_vm.formData.community),callback:function ($$v) {_vm.$set(_vm.formData, "community", $$v)},expression:"formData.community"}}),_c('div',{staticClass:"van-cell--required van-cell van-field"},[_c('div',{staticClass:"van-cell__title van-field__label"},[_c('span',[_vm._v("小区/自然村")])]),_c('div',{staticClass:"van-cell__value van-field__value"},[_c('div',{staticClass:"my my-popover"},[_c('van-popover',{attrs:{"trigger":"click","actions":_vm.actions,"get-container":".u-form"},on:{"select":_vm.xqSelect},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.xqName),expression:"formData.xqName"}],attrs:{"type":"text","placeholder":"请输入所屬小区/自然村地址"},domProps:{"value":(_vm.formData.xqName)},on:{"blur":function($event){_vm.showPopover = false},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "xqName", $event.target.value)},_vm.xqInput]}})]},proxy:true}]),model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}})],1)])]),_c('div',{staticClass:"van-cell--required van-cell van-field"},[_c('div',{staticClass:"van-cell__title van-field__label"},[_c('span',[_vm._v("详细地址")])]),_c('div',{staticClass:"van-cell__value van-field__value"},[_c('div',{staticClass:"my"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.ridgepole),expression:"formData.ridgepole"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.formData.ridgepole)},on:{"click":function($event){_vm.showPicker = true;
              _vm.pickType = 'ridgepole';},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "ridgepole", $event.target.value)}}}),_vm._v("栋 "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.unit),expression:"formData.unit"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.formData.unit)},on:{"click":function($event){_vm.showPicker = true;
              _vm.pickType = 'unit';},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "unit", $event.target.value)}}}),_vm._v("单元 "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.chamber),expression:"formData.chamber"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.chamber)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "chamber", $event.target.value)}}}),_vm._v("室 ")])])]),(_vm.isLadderControl)?_c('van-field',{attrs:{"right-icon":"arrow","readonly":"","required":"","clickable":"","label":"楼层","rules":[{ required: true, message: '请选择楼层' }],"placeholder":"请选择楼层"},on:{"click":function($event){_vm.showPicker = true;
        _vm.pickType = 'floor';}},model:{value:(_vm.formData.floor),callback:function ($$v) {_vm.$set(_vm.formData, "floor", $$v)},expression:"formData.floor"}}):_vm._e()],1),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","title":_vm.pickerTitle,"columns":_vm.columns[this.pickType]},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showAreaPicker),callback:function ($$v) {_vm.showAreaPicker=$$v},expression:"showAreaPicker"}},[_c('van-area',{attrs:{"title":"省市区","area-list":_vm.areaList},on:{"confirm":_vm.onAreaConfirm,"cancel":function($event){_vm.showAreaPicker = false}}})],1),_c('div',{staticClass:"bottom-btn"},[_c('u-button',{attrs:{"type":"primary","size":"large","disabled":_vm.disabled || _vm.sending},on:{"click":_vm.submit}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }