<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-16 09:23:59
 * @LastEditTime: 2020-12-25 15:54:27
-->
<template>
  <div>
    <!-- 个人信息 -->
    <van-form ref="formData"
              class="u-form">
      <van-field v-model="nickName"
                 readonly
                 label="昵称" />
      <van-field right-icon="arrow"
                 required
                 readonly
                 clickable
                 label="职业"
                 v-model="formData.jobName"
                 :rules="[{ required: true, message: '请选择职业' }]"
                 placeholder="点击选择职业"
                 @click="showPicker = true" />
      <van-popup v-model="showPicker"
                 round
                 position="bottom">
        <van-picker show-toolbar
                    title="职业"
                    ref="picker"
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false" />
      </van-popup>
      <van-field v-model="formData.phone"
                 required
                 label="手机号码"
                 maxlength="11"
                 placeholder="请输入手机号码"
                 :rules="[{ required: true, validator:phoneValidator, message: '请输入正确手机号码',trigger :'onBlur' }]" />
      <van-field v-model="formData.yzm"
                 required
                 center
                 clearable
                 maxlength="6"
                 :disabled="!(!yzmDisabled||isSent)"
                 label="验证码"
                 placeholder="短信验证码">
        <template #button>
          <u-button type="primary"
                    size="small"
                    :disabled="yzmDisabled"
                    @click="getVerifyCode">{{isSent?`${countNum}s后再发送`:'获取验证码'}}</u-button>
        </template>
      </van-field>
      <van-field v-model="formData.shortPhone"
                 v-if="formData.topJobCode==='1012'"
                 label="短号"
                 maxlength="6"
                 placeholder="请输入手机短号" />
    </van-form>
    <div class="bottom-btn">
      <u-button type="primary"
                size="large"
                :disabled="nextDisabled||sending"
                @click="next">下一步</u-button>
    </div>
  </div>
</template>

<script>
import UButton from '@/components/u-button';
import api from '@api'
export default {
  components: { UButton },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      roleData: JSON.parse(localStorage.getItem("roleData")),
      nickName: '',
      formData: {
        // nickName: this.roleData.userPO.nickName
      },
      columns: [],
      showPicker: false,
      isSendVerify: true,// 判断是否可用发送验证码
      isSent: false, // 判断是否已经发送验证码
      interval: null,// 倒计时计算
      countNum: 60, //倒计时时间,
      sending: false
    }
  },
  mounted () {
    if (this.roleData.userPO.nickName.length > 6) {
      this.nickName = this.roleData.userPO.nickName.substring(0, 6) + '...'
    } else {
      this.nickName = this.roleData.userPO.nickName
    }
    this.formData = {
      jobName: this.roleData.userPO.jobName,
      jobCode: this.roleData.userPO.jobCode,
      topJobName: this.roleData.userPO.topJobName,
      topJobCode: this.roleData.userPO.topJobCode,
      phone: this.roleData.userPO.phone,
      shortPhone: this.roleData.userPO.shortPhone
    }
    this.getJobData()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    yzmDisabled () {
      return !(this.phoneValidator(this.formData.phone) && this.isSendVerify)
    },
    nextDisabled () {
      return !(this.formData.jobName && this.phoneValidator(this.formData.phone) && this.formData.yzm)
    }
  },
  methods: {
    // 获取职业数据
    getJobData () {
      api.getJobData().then(res => {
        this.columns = res.data.map(item => {
          let top = item.topJob
          let jobs = item.jobList
          return {
            text: top.industryName,
            industryNo: top.industryNo,
            children: jobs.map(job => {
              return {
                text: job.industryName,
                industryNo: job.industryNo,
              }
            })
          }
        })
      })
    },
    // 职业选择
    onConfirm () {
      let value = this.$refs.picker.getValues()
      this.formData.topJobName = value[0].text
      this.formData.topJobCode = value[0].industryNo
      this.formData.jobName = value[1].text
      this.formData.jobCode = value[1].industryNo
      this.showPicker = false;
    },
    // 手机号码验证
    phoneValidator (val) {
      return this.$tools.isPhone(val)
    },
    // 获取验证码
    getVerifyCode () {
      api.getVerifyCode({ phone: this.formData.phone }).then(res => {
        this.isSendVerify = false
        this.isSent = true
        // 重发验证码倒计时
        this.interval = setInterval(() => {
          this.countNum--
          if (!this.countNum) {
            clearInterval(this.interval)
            this.isSendVerify = true
            this.isSent = false
            this.countNum = 60
          }
        }, 1000);
      })
    },
    next () {
      this.$refs.formData.validate().then(res => {
        const toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '数据提交中',
        });
        this.sending = true
        api.savePersonal(this.formData).then(res => {
          this.sending = false
          toast.close()
          if (res.code == "SUCCESS") {
            this.roleData.userPO = Object.assign(this.roleData.userPO, this.formData)
            this.$store.dispatch("setRoleData", this.roleData); //vuex 存roleData 信息
            localStorage.setItem("roleData", JSON.stringify(this.roleData)); //本地缓存roleData 信息
            this.$emit('next')
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>