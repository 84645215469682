<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-15 14:50:27
 * @LastEditTime: 2020-12-15 15:20:55
-->
<template>
  <div class="u-step">
    <div class="u-step-item"
         :class="{active:active>=i}"
         v-for="(step ,i) in steps"
         :key="i">
      <div class="icon">
        <van-icon name="checked" />
      </div>
      <div class="name">
        {{step}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step',
  props: {
    active: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
    }
  },
  components: {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.u-step {
  display: flex;
  color: @fcg;
  padding: .8rem /* 15/18.75 */ 0;
  .u-step-item {
    flex: 1;
    .name {
      font-size: 0.64rem /* 12/18.75 */;
      font-family: PingFang SC;
      font-weight: 400;
      text-align: center;
      margin-top: 0.106667rem /* 2/18.75 */;
    }
    .icon {
      display: flex;
      justify-content: center;
      font-size: 1.12rem /* 21/18.75 */;
      position: relative;
      color: #e6e6e6;
      &::before,
      &::after {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        width: calc(50% - 0.426667rem /* 8/18.75 */);
        height: 2px;
        background: #e6e6e6;
        z-index: 1;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    &.active {
      .icon {
        color: #2b71fd;
        &::before,
        &::after {
          background: #2b71fd;
        }
      }
      color: #2b71fd;
    }
    &:first-child .icon::before {
      display: none;
    }
    &:last-child .icon::after {
      display: none;
    }
  }
}
</style>